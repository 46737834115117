import React, { FC, StrictMode } from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import TermsAndConditions from "./TermsAndConditions";
import initializeTracer from "shared/tracer";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "termsandconditions",
    element: <TermsAndConditions />,
  },
]);

const ErrorFallback: FC<any> = ({ error }) => {
  const { resetBoundary } = useErrorBoundary();

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <button onClick={resetBoundary}>Try again</button>
    </div>
  );
};

initializeTracer()
  .then(() => {
    ReactDOM.render(
      <ErrorBoundary FallbackComponent={ErrorFallback}>
      <StrictMode>
        <RouterProvider router={router} />
      </StrictMode>
    </ErrorBoundary>,
      document.getElementById("root")
    );
  })
  .catch(() => {
    ReactDOM.render(
      <ErrorBoundary FallbackComponent={ErrorFallback}>
      <StrictMode>
        <RouterProvider router={router} />
      </StrictMode>
    </ErrorBoundary>,
      document.getElementById("root")
    );
  });